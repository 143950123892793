/* .details-page .custom-tab-title::marker {
    content: "";
    font-size: 9px;
    float: left;
    margin-top: 10px;
    margin-left: -17px;
    color: #ffffff00;
  }

  .react-bootstrap .nav-link.active {
    background-color: #58b7aa !important;
}

.nav-pills .nav-link.active {
    color: white;
    background-color: #58b7aa !important;
}

.nav-link{
    color: white; 
}


 */

.nav {
  /* --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ; */
  --bs-nav-link-color: none;
  --bs-nav-link-hover-color: none;
  --bs-nav-link-disabled-color: none;
  /* display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; */
}
::marker {
  content: "► ";
}

.no-bullet {
  list-style-type: none;
  padding: 0;
  color: white;
}

.links-vertical {
  color: white;
}

.no-bullet ::marker {
  content: " ";
  font-size: 9px;
  float: left;
  margin-top: 10px;
  margin-left: -17px;
  color: #ffffff;
}

.background-image-container {
  position: relative;
  background-image: url("https://media1.olaple.com/images/videos/1638257242title.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh; /* Set full height */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 0;
}

.content-container {
  position: relative;
  z-index: 1;
}

/* Your existing tab styles */
.react-bootstrap .nav-link.active {
  background-color: #F5679D !important;
}

.nav-pills .nav-link.active {
  color: rgb(255, 255, 255);
  background-color: #F5679D !important;
}

.nav-link {
  color: white;
}

.loader {
  width: 100px;
  aspect-ratio: 1;
  border-radius: 50%;
  /* background: #e20788; */
  box-shadow: 0 0 0 0 #EFBB4E;
  animation: l1 1s infinite;
}
@keyframes l1 {
    100% {box-shadow: 0 0 0 30px #0000}
}